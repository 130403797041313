<div class="modal fade" id="bsModalCreateConta"
data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
   aria-labelledby="modelTitleId" aria-hidden="true">
   <div class="modal-dialog modal-sm" role="document">

      <div class="modal-content">
            <div class="modal-header" style="border: none;">
               <button class="close" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
               </button>
            </div>

            <div class="modal-body">
               <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist"
                  style="display: flex; align-items: center; justify-content: center;">
                  <li class="nav-item active" role="presentation">
                     <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                        aria-controls="pills-home" aria-selected="true">REGISTAR-SE</a>
                  </li>
                  <li class="nav-item" role="presentation">
                     <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                        aria-controls="pills-profile" aria-selected="false">ENTRAR</a>
                  </li>
               </ul>
               <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade active in" id="pills-home" role="tabpanel"
                  aria-labelledby="pills-home-tab">
                     <form [formGroup]="contaFormGroup" (ngSubmit)="create()">
                        <div class="row">
                           <div class="col-md-12 alertConta"></div>
                           <div class="col-md-12 form-group" formGroupName="info">
                              <input type="text" formControlName="nome" class="form-control"
                              [ngClass]="form.classError(submitForm, infoGroup.get('nome'))"
                              placeholder="Nome">
                              <article class="text-danger">
                                 {{form.validate('required', submitForm, infoGroup.get('nome'), null )}}
                              </article>
                           </div>

                           <div class="col-md-12 form-group">
                              <input type="mail" formControlName="email" class="form-control"
                              [ngClass]="form.classError(submitForm, contaForm.email)"
                              placeholder="Email">
                              <article class="text-danger">
                                 {{form.validate('email', submitForm, contaForm.email,null )}}
                              </article>
                           </div>

                           <div class="col-md-12 form-group">
                              <input type="password" formControlName="password" class="form-control"
                              [ngClass]="form.classError(submitForm, contaForm.email)"
                              placeholder="Senha">
                              <article class="text-danger">
                                 {{form.validate('password', submitForm, contaForm.password, null )}}
                              </article>
                           </div>

                           <div class="col-md-12 form-group">
                              <select class="form-control" formControlName="permissaoId"
                              [ngClass]="form.classError(submitForm, contaForm.type)">
                                 <option value="">Tipo da conta</option>
                                 <ng-container *ngFor="let perm of permissoes; index as i ">
                                    <option *ngIf="perm.editable == 0"  [value]="perm.id">{{perm.nome}}</option>
                                 </ng-container>
                              </select>
                              <article class="text-danger">
                                 {{form.validate('password', submitForm, contaForm.type,
                                 'Selecione uma opção' )}}
                              </article>
                           </div>

                           <div class="col-md-12">
                              <button type="submit" class="btn btn-primary btn-100
                               btnActionConta">CRICAR CONTA</button>

                              <p class="text-center" style="margin-top: 20px; line-height: 20px;">
                                 Ao criar uma conta, você concorda com o<br> os termos e condições da xawandi
                              </p>
                           </div>
                        </div>
                     </form>
                  </div>

                  <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                  aria-labelledby="pills-profile-tab">
                     <app-auth></app-auth>
                  </div>
               </div>
            </div>
      </div>
   </div>
</div>
