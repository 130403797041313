import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';


@Injectable({
   providedIn: "root"
})
export class ProdutoService{

   private readonly API = `${environment.api}/produtos`
   constructor(private http: HttpClient){}

   select(values: object){
      return this.http.post<any>(this.API, values).pipe(take(1))
   }

   selectByUrl(values: object){
      return this.http.post<any>(this.API+"/edit", values).pipe(take(1))
   }

   selectByLoja(obj: any){
      return this.http.post<any>(this.API+"/select", {
         type: "lojaUrl",
         lojaUrl: obj.lojaUrl,
         categoriaId: obj.catId
      })
   }

   selectByCategoria(url: string){
      return this.http.post<any>(this.API+"/edit", {
         type: "categoriaUrl",
         url: url
      }).pipe(take(1))
   }


}
