import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http'
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/es';
registerLocaleData(localeFr, 'es');

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
//import { AuthModule } from './components/auth/auth.module';
//import { StoreModule } from './components/store/store.module';

//import { ConfigService } from './services/config.service';
import { CategoriasService } from './components/categorias/categorias.service';
import { AppService } from './app.service';
import { ProdutoService } from './components/produtos/produtos.service';
import { HttpCartInterceptor } from './interceptors/cart.interceptor';
import { HttpAuthInterceptor } from './interceptors/auth.interceptor';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { SobreComponent } from './components/sobre/sobre.component';
import { ContactosComponent } from './components/contactos/contactos.component';
import { DestaquesComponent } from './components/home/destaques/destaques.component';
import { NavComponent } from './components/header/nav/nav.component';
import { CreateContaComponent } from './components/contas/create/create.component';
import { AuthComponent } from './components/auth/auth.component';
import { CategoriasComponent } from './components/header/categorias/categorias.component';

@NgModule({
   declarations: [
      AppComponent,
      HeaderComponent,
      NavComponent,
      FooterComponent,
      HomeComponent,
      DestaquesComponent,
      SobreComponent,
      ContactosComponent,
      CreateContaComponent,
      AuthComponent,
      CategoriasComponent
   ],
   imports: [
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      HttpClientModule,
      LoadingBarModule,
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
      SharedModule,
      AppRoutingModule
   ],
   exports:[],
   providers: [
      HttpCartInterceptor,
      HttpAuthInterceptor,
      {provide: LOCALE_ID,useValue: "es"},
      CategoriasService, AppService,
      ProdutoService
   ],
   bootstrap: [AppComponent]
})
export class AppModule { }
