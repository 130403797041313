import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate, CanDeactivate } from '@angular/router';

import { AuthGuards} from './guards/auth.guard';

import { HomeComponent } from './components/home/home.component';
import { ContactosComponent } from './components/contactos/contactos.component';
import { SobreComponent } from './components/sobre/sobre.component';

const AppRoutes: Routes = [
   { path: '', component: HomeComponent },
   { path: 'sobre',component: SobreComponent },
   { path: 'contactos',component: ContactosComponent },

   {path:'produtos', loadChildren: ()=> import('./components/produtos/produtos.module')
      .then(module => module.ProdutoModule)
   },
   {path: 'cart', loadChildren: ()=> import('./components/store/cart/cart.module')
      .then(module => module.CartModule)
   },
   {path: 'painel', loadChildren: ()=> import('./components/painel-clientes/painel-clientes.module')
      .then(module => module.PainelClientesModule),
      canActivate: [AuthGuards]
   },

   //{path: '**', component: NotFoundComponent},
]

@NgModule({
   imports: [RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: 'reload'
   })],
   exports: [RouterModule]
})
export class AppRoutingModule { routes: object }

