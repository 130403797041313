import { AppService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../shared/shared.service';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.css'],
})
export class FooterComponent implements OnInit {
  navFooter: object;
  copyright: number

  constructor(
    public app: AppService,
    public shared: SharedService) {
      const date = new Date()
      this.copyright = date.getFullYear()
    }

  ngOnInit() {
    this.navFooter = {
      suporte: [
        { title: 'Como comprar', url: 'como-comprar' },
        { title: 'Meio de pagamento', url: 'meio-pagamento' },
        { title: 'Logística e destribuição', url: 'logistica-e-destribuicao' },
        { title: 'Devoluções e trocas', url: 'devolucoes-e-trocas' },
      ],

      suporte2: [
        { title: 'Como vender no bazara', url: 'como-vender' },
        { title: 'Sugestões e reclamações', url: 'sugstestoes-e-reclamacoes' },
        { title: 'Investidores', url: '' },
      ],

      Lojas: [
        { title: 'Loja1', url: 'vantagens' },
        { title: 'Audiência Alargada', url: 'audiencia' },
        { title: 'Marketing Gratuíto', url: 'marketing-gratis' },
        { title: 'Onboarding e Apoio Contínuo', url: 'onboarding' },
        { title: 'Sem custos Fixos', url: '' },
      ],

      comoVender2: [
        { title: 'Account Manager Dedicado', url: '' },
        { title: 'Cobertura Editorial', url: '' },
        { title: 'Serviços de Logísticas e Destribuição integrados', url: '' },
        { title: 'Centro de vendas em real time', url: '' },
      ],
    };
  }
}
