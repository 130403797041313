<div class="header_bottom bottom_two sticky-header" style="border: none !important;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="header_bottom_container">
          <div class="categories_menu">

            <div class="categories_title" style="background-color: transparent;">
              <h2 class="categori_toggle">Todas categorias</h2>
            </div>

            <div class="categories_menu_toggle">
              <ul>
                <ng-container *ngFor="let item of categorias; index as i">
                  <li class="menu_item_children categorie_list" *ngIf="i <= 9">
                    <a routerLink=""> {{item.info.nome}}
                      <i class="fa fa-angle-right" *ngIf="item.info.sub.length > 0" ></i>
                    </a>
                    <ul class="categories_mega_menu column_2" *ngIf="item.info.sub.length > 0">
                      <li class="menu_item_children"><a href=""></a>
                        <ul class="categorie_sub_menu">
                          <li *ngFor="let sub of item.info.sub" >
                            <a >{{sub}}</a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ng-container>
                <li><a href="#" id="more-btn">
                    <i class="fa fa-plus" aria-hidden="true"></i> Mais Categorias</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="main_menu">
            <nav>
              <ul>
                <li><a routerLink="">Início</a></li>
                <li><a routerLink=""> Ofertas do dia</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>




















<div class="header-menu" style="display: none;">
  <div class="megamenu-style-dev megamenu-dev">
    <div class="responsive">
      <nav class="navbar-default">
        <div class="container-megamenu horizontal">
          <div class="navbar-header">
            <button type="button" id="show-megamenu" data-toggle="collapse" class="navbar-toggle">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="megamenu-wrapper">
            <span id="remove-megamenu" class="fa fa-times"></span>
            <div class="megamenu-pattern">
              <div class="container">
                <ul class="megamenu" data-transition="slide" data-animationtime="500">

                  <li class="full-width menu-home with-sub-menu hover" *ngFor="let item of navMenu">
                    <p class="close-menu"></p>
                    <a routerLink="{{item.url}}" routerLinkActive="active" class="clearfix">
                      {{item.title}}
                      <!-- <b class="caret"></b> -->
                    </a>
                  </li>

                  <li class="full-width menu-home with-sub-menu hover">
                    <p class="close-menu"></p>
                    <a class="clearfix"> Registar-se</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
