import { RouterModule } from '@angular/router';
import { ItemProdutoComponent } from './../components/produtos/item/item.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

//import { LoadingBarModule } from '@ngx-loading-bar/core';
//import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
//import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { SharedService } from './shared.service';
import { FormValidationService } from './forms/form.validation';

import { SkeletonLoadComponent } from './skeleton-load/skeleton-load.component';
import { SpinnersComponent } from './spinners/spinners.component';


@NgModule({
   declarations:[
      SkeletonLoadComponent,
      SpinnersComponent,
      ItemProdutoComponent
   ],
   imports:[
      CommonModule,
      RouterModule,
      //FormsModule,
      //NgbModule,
      //LoadingBarModule,
      //LoadingBarHttpClientModule,
      //LoadingBarRouterModule,
      NgxSkeletonLoaderModule,
   ],
   exports:[
     SkeletonLoadComponent, SpinnersComponent, ItemProdutoComponent
   ],
   providers:[
      FormValidationService,
      SharedService
   ]
})

export class SharedModule{
   /*constructor(
      @Optional() @SkipSelf() parentModule: SharedModule
   ){
      if(parentModule){
         throw new Error('O módlulo Shared já está a ser lido e só pode ser importado uma única vez');
      }
   }*/
}
