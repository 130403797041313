<!-- Breadcrumb area Start -->
<div class="breadcrumb-area bg--white-6 pt--60 pb--70 pt-lg--40 pb-lg--50 pt-md--30 pb-md--40">
   <div class="container-fluid">
      <div class="row">
         <div class="col-12 text-center">
            <h1 class="page-title">Sobre</h1>
            <ul class="breadcrumb justify-content-center">
               <li><a routerLink="">Início</a></li>
               <li class="current"><span>Sobre Nós</span></li>
            </ul>
         </div>
      </div>
   </div>
</div>
<!-- Breadcrumb area End -->

<!-- Main Content Wrapper Start -->
<div id="content" class="main-content-wrapper">
   <div class="page-content-inner">
      <div class="container">

         <div class="row pt--80 pt-md--60 pt-sm--35 pb--40 pb-md--30 pb-sm--15">
            <div class="col-lg-6 col-md-6 mb-sm--30">
               <div class="about-text">
                  <h3 class="heading-tertiary heading-color mb--15" style="text-transform: none;">
                  O nosso objectivo</h3>

                  <p class="color--light-3 mb--25 mb-md--20" style="text-align: justify;">
                     Reunir na mesma plataforma online, marcas angolanas e não só,
                     e chegar a todos os pontos do país. Aqui irá encontrar uma
                     diversidade de oferta que habitualmente não encontra nas lojas.
                     Queremos mudar a forma como se compra e vende online no nosso país,
                     e ajudar a potenciar as marcas abrindo as portas do mundo aos seus
                     negócios. Claro que não temos só marcas angolanas, mas queremos ser
                     um Marketplace de angolanos para angolanos.
                  </p>

                  <p class="color--light-3 mb--25" style="margin-top: 60px">
                     Este é o primeiro Marketplace só de moda em Angola, feito a
                     pensar no que as angolanas e angolanos gostam de comprar,
                     sem stresses de trânsito, sem ter de procurar lugar para o carro,
                     perder horas e voltar frustrados a casa sem comprar o que querem
                      porque já não há, ou nunca houve. O nosso objectivo é apoiar
                       os negócios nacionais em tempos de crise
                  </p>
               </div>
            </div>
            <div class="col-lg-6 col-md-6">
               <figure>
                  <img src="{{app.IMG_PATH}}about/about-bg2.jpg" alt="about">
               </figure>
            </div>
         </div>

      </div>
   </div>
</div>
<!-- Main Content Wrapper Start -->
