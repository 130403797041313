<div class="megamenu-style-dev megamenu-dev">
  <div class="responsive">
    <div class="so-vertical-menu no-gutter">
      <nav class="navbar-default">
        <div class=" container-megamenu  container   vertical  ">
          <div id="menuHeading">
            <div class="megamenuToogle-wrapper">
              <div class="megamenuToogle-pattern">
                <div class="container" style="cursor: pointer;">
                  <div><span></span><span></span><span></span></div>
                  <span class="title-mega">
                    Categorias
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="navbar-header">
            <span class="title-navbar hidden-lg hidden-md"> All Categories </span>
            <button type="button" id="show-verticalmenu" data-toggle="collapse" class="navbar-toggle">
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="vertical-wrapper">
            <span id="remove-verticalmenu" class="fa fa-times"></span>
            <div class="megamenu-pattern">
              <div class="container">
                <skeleton-load *ngIf="loaderCart"
                [options]="{type:'categorias', items:6 }"></skeleton-load>

                <ul class="megamenu" data-transition="slide" data-animationtime="300" >
                  <ng-container *ngFor="let categoria of categorias; index as i">
                    <li class="item-vertical style1" *ngIf="i <= 10">
                      <p class="close-menu"></p>
                      <a class="clearfix" routerLink="produtos/categorias/{{categoria.url}}">
                        <span>
                          <strong>
                            <!-- <img src="image/catalog/demo/menu/icon/icon-6.png" alt=""> -->
                            {{categoria.info.nome}}
                          </strong>
                        </span>
                      </a>
                    </li>
                  </ng-container>

                  <!-- <li class="item-vertical  css-menu with-sub-menu hover">
                    <p class="close-menu"></p>
                    <a href="#" class="clearfix">
                      <span>
                        <strong><img src="image/catalog/demo/menu/icon/icon-8.png" alt="">Spa &amp;
                          Massage</strong>
                      </span>
                      <b class="fa fa-caret-right"></b>
                    </a>
                    <div class="sub-menu" style="width: 250px;">
                      <div class="content">
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="categories ">
                              <div class="row">
                                <div class="col-sm-12 hover-menu">
                                  <div class="menu">
                                    <ul>
                                      <li>
                                        <a href="#" onclick="window.location = '#';" class="main-menu">Book
                                          Stationery<b class="fa fa-angle-right"></b></a>
                                        <ul>
                                          <li><a href="#" onclick="window.location = '#';">Camera
                                              New</a></li>
                                          <li><a href="#" onclick="window.location = '#';">Dress
                                              Ladies</a></li>
                                          <li><a href="#" onclick="window.location = '#';">Jean</a>
                                          </li>
                                          <li><a href="#" onclick="window.location = '#';">Case</a>
                                          </li>
                                        </ul>
                                      </li>
                                      <li>
                                        <a href="#" onclick="window.location = '#';" class="main-menu">Book
                                          Stationery<b class="fa fa-angle-right"></b></a>
                                        <ul>
                                          <li><a href="#" onclick="window.location = '#';">Girls
                                              New</a></li>
                                          <li>
                                            <a href="#" onclick="window.location = '#';">Pearl
                                              mens<b class="fa fa-angle-right"></b></a>
                                            <ul>
                                              <li><a href="#" onclick="window.location = '#';">Bluetooth
                                                  Speakers</a></li>
                                            </ul>
                                          </li>
                                          <li><a href="#" onclick="window.location = '#';">Fashion
                                              &amp; Accessories</a></li>
                                          <li>
                                            <a href="#" onclick="window.location = '#';">Trending<b
                                                class="fa fa-angle-right"></b></a>
                                            <ul>
                                              <li><a href="#" onclick="window.location = '#';">Punge
                                                  nenune</a></li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                      <li><a href="#" onclick="window.location = '#';"
                                          class="main-menu">Kitchen</a></li>
                                      <li>
                                        <a href="#" onclick="window.location = '#';" class="main-menu">Book
                                          Stationery<b class="fa fa-angle-right"></b></a>
                                        <ul>
                                          <li><a href="#" onclick="window.location = '#';">Cell
                                              &amp; Cable</a></li>
                                          <li><a href="#" onclick="window.location = '#';">Camera
                                              New</a></li>
                                          <li><a href="#" onclick="window.location = '#';">Digital
                                              &amp; Electronics</a></li>
                                          <li><a href="#" onclick="window.location = '#';">Pearl
                                              mens</a></li>
                                        </ul>
                                      </li>
                                      <li>
                                        <a href="#" onclick="window.location = '#';"
                                          class="main-menu">Case<b class="fa fa-angle-right"></b></a>
                                        <ul>
                                          <li>
                                            <a href="#" onclick="window.location = '#';">Bluetooth
                                              Speakers<b class="fa fa-angle-right"></b></a>
                                            <ul>
                                              <li><a href="#" onclick="window.location = '#';">Cell
                                                  &amp; Cable</a></li>
                                              <li><a href="#" onclick="window.location = '#';">Bags</a>
                                              </li>
                                              <li><a href="#" onclick="window.location = '#';">Women
                                                  Fashion</a></li>
                                            </ul>
                                          </li>
                                        </ul>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> -->

                  <li class="loadmore">
                    <i class="fa fa-plus-square"></i>
                    <span class="more-view"> Mais ctegorias</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
