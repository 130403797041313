import { take } from 'rxjs/operators';
import { API } from './../../../services/api';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from "@angular/core";
import { environment } from './../../../../environments/environment';
import { promise } from 'protractor';


@Injectable({
   providedIn: 'root'
})
export class CartService{

   private readonly API = `${environment.api}/cart`
   res: any
   changed = new EventEmitter<boolean>()

   constructor(private http: HttpClient){}

   getItemsCart(){
      return this.http.get<any>(this.API+'/items').pipe(take(1))
   }

   getOrders(){
      return this.http.get<any>(`${environment.api}/orders`).pipe(take(1))
   }

   async filter(values: any){
      return await this.http.post<any>(this.API+'/filter', values).toPromise()
   }

   async addToCart(values: any){
      const res = await this.http.post<any>(this.API+"/add", values).toPromise()
      if(res.cartToken){
         localStorage.setItem("cartToken", res.cartToken)
      }
      return res
   }

   updateItemCart(obj: object){
      return this.http.put<any>(this.API+"/items/update", obj).pipe(take(1))
   }

   async removeItemCart(id: number): Promise<any>{
      return await this.http.post<any>(this.API+"/items/delete", {
         itemId: id
      }).toPromise()

      //this.changed.emit(true)
   }

   checkoutCart(values: object){
      return this.http.post<any>(this.API+'/checkout', values).pipe(take(1))
   }

   async cartExist(): Promise<any>{
      return await this.http.get(this.API+'/items').toPromise()
   }

   getCartToken(){
      return localStorage.getItem('cartToken')
   }
}
