import { AppService } from './app.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './components/auth/auth.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.html',
   styleUrls: ['./app.css']
})
export class AppComponent implements OnInit {
   title = 'invoice';
   userData: object
   isHome: string
   settings: any

   constructor(
      private app: AppService,
      private authService: AuthService,
      private router: Router
   ){
      this.isHome = router.url
      this.router.events.subscribe(event => {
         this.isHome = router.url
      })

      this.settings = { empresa:{}, social:{}, seo:{} }
   }

   ngOnInit() {

      /*if(this.authService.signed && window.localStorage.getItem("token")){
         this.userData = {
            isAuth: true,
            nome: window.localStorage.getItem("userNome")
         }
      }else{
         this.userData = {}
      }*/

      this.app.select().then(res => {
         this.settings = res.settings
     })
   }
}
