<div class="single_product">
  <div class="product_name">
    <h3>
      <a routerLink="/produtos/{{produto.url}}">{{produto.info.nome}}</a>
    </h3>
    <!--<p class="manufacture_product"><a href="#">Accessories</a></p>-->
  </div>

  <div class="product_thumb">
    <a routerLink="/produtos/{{produto.url}}" class="primary_img">
      <img src="{{app.filesPath+prodImage[0].file.name}}">
    </a>

    <a routerLink="/produtos/{{produto.url}}" class="secondary_img">
      <img src="{{app.filesPath+prodImage[0].file.name}}">
    </a>

    <div class="label_product">
      <span class="label_sale">-57%</span>
    </div>

    <div class="action_links" style="display: none;">
      <ul>
        <li class="quick_button">
          <a href="#">
            <span class="lnr lnr-magnifier"></span>
          </a>
        </li>

        <li class="wishlist">
          <a href="wishlist.html" title="Add to Wishlist">
            <span class="lnr lnr-heart"></span>
          </a>
        </li>

        <li class="compare">
          <a href="compare.html" title="compare">
            <span class="lnr lnr-sync"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="product_content">
    <div class="product_ratings">
      <ul>
        <li><a href="#"><i class="ion-star"></i></a></li>
        <li><a href="#"><i class="ion-star"></i></a></li>
        <li><a href="#"><i class="ion-star"></i></a></li>
        <li><a href="#"><i class="ion-star"></i></a></li>
        <li><a href="#"><i class="ion-star"></i></a></li>
      </ul>
    </div>
    <div class="product_footer d-flex align-items-center">
      <div class="price_box">
        <span class="regular_price mr-2">{{produto.info.price | currency:'Kz'}}</span>
        <span class="old_price">{{produto.info.oldPrice | currency:'Kz'}}</span>
      </div>

      <div class="add_to_cart">
        <a (click)="addToCart(produto.id)">
          <span class="lnr lnr-cart"></span></a>
      </div>
    </div>
  </div>
</div>

