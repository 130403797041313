import { Component, OnInit } from '@angular/core';
import { ConfigService } from './../../services/config.service';


@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.html',
  styleUrls: ['./sobre.css']
})
export class SobreComponent implements OnInit {

  constructor(public app: ConfigService) { }

  ngOnInit(): void {
  }

}
