import { Router } from '@angular/router';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { ContaService } from './../conta.service';
import { FormValidationService } from './../../../shared/forms/form.validation';
import { SharedService } from './../../../shared/shared.service';
import { AppService } from './../../../app.service';
import { AuthService } from '../../auth/auth.service';

@Component({
   selector: "app-create-conta",
   templateUrl: "./create.html",
   styleUrls: ["./create.css"]
})
export class CreateContaComponent implements OnInit {
   contaFormGroup: FormGroup
   submitForm: boolean = false;
   validate: any
   submitted: boolean = false
   conta: object[]
   permissoes: any[] = []

   constructor(
      public app: AppService,
      public shared: SharedService,
      public form: FormValidationService,
      private contaService: ContaService,
      private formBuilder: FormBuilder,
      private authService: AuthService,
      private router: Router
   ) { }

   async create() {
      this.submitted = true;
      if(this.contaFormGroup.valid) {

         this.shared.btnSpinner(true, {btn:'.btnActionConta'})
         await this.contaService.create(this.contaFormGroup.value).then(res =>{
            //console.log(res)
            this.shared.btnSpinner(false, {btn:'.btnActionConta'})
            this.shared.alertMsg({
               show: true,
               delay:500,
               div:'.alertConta',
               type: res.id ? 'success' : 'danger',
               msg: res.id ? "Conta criada com sucesso": res.msg
            })

            if(res.id){
               setTimeout(()=>{
                  this.shared.bsModal({show: false, id:"#bsModalCreateConta"})
                  if(res.type == 'Vendedor'){
                     window.open(this.app.backofficeApi+"redirect", "_blank")
                  }

                  if(res.type == 'Cliente'){
                     window.localStorage.setItem("userToken", res.token)
                     window.localStorage.setItem("userLevel", res.type);
                     this.authService.signed.emit(true)
                     this.router.navigate(['/painel'])
                  }
               },2000)
            }
         })
      }
   }

   ngOnInit() {
      this.contaFormGroup = this.formBuilder.group({
         email: ['', [Validators.required, Validators.email]],
         password: ['', Validators.required],
         /*password: ['', Validators.compose([
            Validators.required, this.form.passwordValidator()
         ])],*/
         info: this.formBuilder.group({
            nome: ['', Validators.required],
            phone: [''],
            images: this.formBuilder.array([])
         }),
         permissaoId: ['', Validators.required],
         status:[1],
         type: ['Cliente'],
      })

      this.app.getPerms().then(perms => {
         this.permissoes = perms
         //console.log(perms)
      })
   }

   get contaForm() { return this.contaFormGroup.controls }
   get infoGroup() { return this.contaFormGroup.get('info') }

}
