<header class="header_area" >
  <!--header top start-->
  <div class="header_top">
    <div class="container">
      <div class="top_inner">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="follow_us">
              <label>Siga-nos:</label>
              <ul class="follow_link">
                <li><a href="#"><i class="ion-social-facebook"></i></a></li>
                <li><a href="#"><i class="ion-social-twitter"></i></a></li>
                <li><a href="#"><i class="ion-social-googleplus"></i></a></li>
                <li><a href="#"><i class="ion-social-youtube"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="top_right text-right">
              <ul>
                <li class="top_links" *ngIf="!user.id">
                  <a href="javascript:void(0)"
                  (click)="shared.bsModal({id:'#bsModalCreateConta', show: true})">
                    <i class="ion-android-person"></i>
                    Entrar/Registar
                  </a>
                </li>

                <li class="top_links" *ngIf="user.id">
                  <a href="#">
                    <i class="ion-android-person"></i> Olá, {{user.info.nome}}
                    <i class="ion-ios-arrow-down"></i>
                  </a>

                  <ul class="dropdown_links">
                    <li><a routerLink="/painel">Minha Conta</a></li>
                    <li><a routerLink="/painel">Favoritos</a></li>
                    <li><a routerLink="/painel">Historico de compras</a></li>
                    <li><a (click)="signOut()">Sair</a></li>
                  </ul>
                </li>

                <li class="currency">
                  <a href="#">AOA
                    <!-- <i class="ion-ios-arrow-down"></i> -->
                  </a>
                  <!-- <ul class="dropdown_currency">
                    <li><a href="#">EUR – Euro</a></li>
                    <li><a href="#">GBP – British Pound</a></li>
                    <li><a href="#">INR – India Rupee</a></li>
                  </ul> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--header top start-->

  <!--header middel start-->
  <div class="header_middle">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6">
          <div class="logo">
            <a routerLink="">
              <img src="{{app.IMG_PATH}}logo/logo.png" alt="">
            </a>
          </div>
        </div>

        <div class="col-lg-9 col-md-6">
          <div class="middel_right">
            <div class="search-container">
              <form action="#">
                <div class="search_box">
                  <input placeholder="Pesquisar" type="text">
                  <button type="submit"><i class="ion-ios-search-strong"></i></button>
                </div>
              </form>
            </div>
            <div class="middel_right_info">

              <div class="header_wishlist mr-5">
                <a >
                  <span class="lnr lnr-heart"></span>
                </a>
                <span class="wishlist_quantity">3</span>
              </div>

              <div class="mini_cart_wrapper">
                <a href="javascript:void(0)">
                  <span class="lnr lnr-cart"></span>
                </a>
                <span class="cart_quantity">{{totalItems}}</span>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  <!--header middel end-->

  <!--mini cart-->
  <div class="mini_cart" *ngIf="cartItems.items.length > 0 && totalItems > 0">
    <div class="cart_close">
      <div class="cart_text">
        <h3>Carrinho</h3>
      </div>
      <div class="mini_cart_close">
        <a href="javascript:void(0)"><i class="ion-android-close"></i></a>
      </div>
    </div>

    <div class="cart_item" *ngFor="let item of cartItems.items">
      <div class="cart_img">
        <a routerLink="produtos/{{item.produtoUrl}}">
          <img src="{{app.filesPath+item.produtoInfo.images[0].file.name}}" alt="">
        </a>
      </div>
      <div class="cart_info">
        <a routerLink="produtos/{{item.produtoUrl}}">
        {{item.produtoInfo.nome}}</a>

        <span class="quantity">Qtd: {{item.qtd}}</span>
        <span class="price_cart">{{item.subtotal | currency:'Kz'}}</span>

      </div>
      <div class="cart_remove">
        <a (click)="removeItemCart(item.id)"><i class="ion-android-close"></i></a>
      </div>
    </div>

    <div class="mini_cart_table">
      <div class="cart_total">
        <span>Total:</span>
        <span class="price">{{cartItems.totalCart | currency: 'Kz'}}</span>
      </div>
    </div>

    <div class="mini_cart_footer">
      <div class="cart_button">
        <a routerLink="cart">Ver Carrinho</a>
      </div>
      <div class="cart_button">
        <a routerLink="cart/checkout" class="active">Checkout</a>
      </div>
    </div>
  </div>
  <!--mini cart end-->

  <!--header nav satrt-->
  <app-nav *ngIf="isNav"></app-nav>
  <!--header nav end-->
</header>


