import { CartService } from './../../store/cart/cart.service';
import { SharedService } from './../../../shared/shared.service';
import { Component, Input, OnInit } from '@angular/core';
import { AppService } from './../../../app.service';

declare var $: any;

@Component({
  selector: 'produto-item',
  templateUrl: './item.html',
})
export class ItemProdutoComponent implements OnInit {
  @Input() produto: any;
  prodImage: any[] = [];

  constructor(
    public app: AppService,
    public shared: SharedService,
    private cartService: CartService
  ) {}

  addToCart(id: number) {
    const obj = { qtd: 1, produtoId: id, lojaId: 0 };
    this.shared.spinner(true, {});

    this.cartService.addToCart(obj).then((res) => {
      this.shared.spinner(false, {});

      if (res.cart_id) {
        $('#bsModalCart').modal('show');
        this.cartService.changed.emit(true);
      } else {
        this.shared.alertPush({
          msg: res.msg,
          type: res.cart_id ? 'success' : 'error',
          position: 'top-center',
          delay: 4,
        });
      }
    });
  }

  ngOnInit(): void {
    this.prodImage = this.produto.info.images;
  }
}
