import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" })
export class AppService {
   private readonly API = `${environment.api}`;

   IMG_PATH: string = "../../../assets/uploads/"
   filesPath: string = "http://localhost:4300/assets/cdn/files/"
   backofficeApi: string = "http://localhost:4400/"

   constructor(private http: HttpClient) {}

   async select(): Promise<any> {
      return await this.http.get<any[]>(this.API+"/settings").toPromise();
   }

   async getPerms(): Promise<any>{
      const perms = []
      const res = await this.http.get<any[]>(this.API+"/permissoes").toPromise();

      res.forEach(perm => {
         if(perm.id == 1 || perm.id == 2){
            perms.push(perm)
         }
      })

      return perms
   }
}
