import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Component, OnInit, Input, HostListener } from '@angular/core';

import { CartService } from './../store/cart/cart.service';
import { AuthService } from './../auth/auth.service';
import { AppService } from './../../app.service';
import { SharedService } from './../../shared/shared.service';

declare var $: any;
declare var closeItems: any;
declare var miniCart: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.css'],
})
export class HeaderComponent implements OnInit {
  @Input() userData: object = {};
  @Input() isNav: boolean;
  @Input() settings: any;
  user: any = {};

  cartItems: any;
  totalItems: number = 0;
  lojaUrl: string;

  constructor(
    public app: AppService,
    public shared: SharedService,
    private cartService: CartService,
    private authService: AuthService,
    private router: Router
  ) {
    this.cartItems = { cart: {}, items: [] };
    //this.lojaUrl = localStorage.getItem("lojaUrl")
  }

  getItemsCart() {
    this.cartService.getItemsCart().subscribe((res) => {
      if (res.items) {
        this.cartItems = res;
        this.totalItems = parseInt(this.cartItems.cart.status)
          ? 0
          : this.cartItems.totalItems;
      } else {
        this.totalItems = 0;
        this.cartItems.items = [];
      }
      //console.log(res)
    });
  }

  getUserAuth() {
    this.authService.authenticatedUser().then((res) => {
      this.user = res;
      //console.log(res)
    });
  }

  removeItemCart(itemId: number) {
    this.cartService.removeItemCart(itemId).then((res) => {
      this.shared.alertPush({
        msg: res.msg,
        type: res.success ? 'success' : 'error',
        position: 'top-center',
        delay: 2,
      });

      this.router.navigate([this.router.url]);
    });
  }

  signOut() {
    this.authService.signOut();
  }

  ngOnInit(): void {
    miniCart();

    this.getItemsCart();
    this.getUserAuth();
    this.authService.signed.subscribe((event) => {
      this.getUserAuth();
    });

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        if (
          this.router.url == '/cart' ||
          this.router.url == '/cart/checkout/pagamento' ||
          this.router.url == '/cart/checkout/confirm'
        ) {
          //this.cartService.changed.emit(true)
          this.getItemsCart();
          window.scrollTo(0, 0);
        }
      }
    });
  }
}
