<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
<div ngClass="app-spinner" style="color: #06b2e6;"></div>

<app-header [settings]="settings" [isNav]="isHome == '/' ? true : false"></app-header>

<router-outlet></router-outlet>
<app-footer></app-footer>


<app-create-conta></app-create-conta>
