import { CategoriasService } from 'src/app/components/categorias/categorias.service';
import { SharedService } from './../../../shared/shared.service';
import { AppService } from './../../../app.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.html',
  styleUrls: ['./categorias.css']
})
export class CategoriasComponent implements OnInit {
  categorias: any[];
  loaderCart: boolean = true;

  constructor(
    public app: AppService,
    public shared: SharedService,
    private categoriasService: CategoriasService
  ) { }

  ngOnInit(): void {
    this.categoriasService.select().subscribe((res) => {
      this.categorias = res;
      this.loaderCart = false;
    });
  }
}
