import { Injectable } from "@angular/core";
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, take } from 'rxjs/operators';

@Injectable({
   providedIn: "root"
})
export  class CategoriasService{
   private readonly API = `${environment.api}/categorias`

   constructor(private http: HttpClient){}

   select(){
      return this.http.get<any[]>(this.API).pipe(
         take(1)
      )
   }

   selectByUrl(obj: any){
      return this.http.post<any>(this.API+"select", {
         type: "url",
         url: obj.url
      }).pipe(take(1))
   }
}
