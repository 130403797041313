<app-destaques></app-destaques>

<!--product area start-->
<section class="product_area mb-50">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section_title">
          <h2><span> <strong>Melhores</strong> ofertas</span></h2>
        </div>
      </div>

      <skeleton-load class="load" *ngIf="loader"
      [options]="{type:'produtoList', colums: 3, items:4}"></skeleton-load>

      <div class="col-12">
        <div class="product_carousel product_column5 owl-carousel">
          <ng-container *ngFor="let produto of bestOffers">
            <produto-item [produto]="produto"></produto-item>
          </ng-container>
        </div>
      </div>
    </div>

  </div>
</section>
<!--product area end-->

<!--banner area start-->
<section class="banner_area mb-50">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="banner_container">
          <div class="single_banner">
            <div class="banner_thumb">
              <a href="#"><img src="{{app.IMG_PATH}}bg/banner3.jpg" alt=""></a>
              <div class="banner_text">
                <h3>Car Audio</h3>
                <h2>Super Natural Sound</h2>
                <a href="shop.html">Shop Now</a>
              </div>
            </div>
          </div>
          <div class="single_banner">
            <div class="banner_thumb">
              <a href="#"><img src="{{app.IMG_PATH}}bg/banner4.jpg" alt=""></a>
              <div class="banner_text">
                <h3>All - New</h3>
                <h2>Perfomance Parts</h2>
                <a href="shop.html">Shop Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--banner area end-->

<!--product area start-->
<section class="new_product mb-50">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section_title">
          <h2><span> <strong>Especialmente</strong> para sí</span></h2>
          <ul class="product_tab_button nav" role="tablist" style="display: none;">
            <li>
              <a class="active" data-toggle="tab" href="#brake" role="tab" aria-controls="brake"
                aria-selected="true">Brake Parts</a>
            </li>
            <li>
              <a data-toggle="tab" href="#wheels" role="tab" aria-controls="wheels" aria-selected="false">Wheels &
                Tires</a>
            </li>
            <li>
              <a data-toggle="tab" href="#turbo" role="tab" aria-controls="turbo" aria-selected="false">Turbo System</a>
            </li>
          </ul>
        </div>
      </div>

      <skeleton-load class="load" *ngIf="loader" [options]="{type:'produtoList', colums: 3, items:8}">
      </skeleton-load>
    </div>

    <div class="tab-content">
      <div class="tab-pane fade show active" id="brake" role="tabpanel">
        <div class="product_carousel product_column5 owl-carousel">
          <div class="single_product_list" *ngFor="let items of produtos">
            <ng-container *ngFor="let produto of items" >
              <produto-item [produto]="produto"></produto-item>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="wheels" role="tabpanel">
        <div class="product_carousel product_column5 owl-carousel">

        </div>
      </div>

      <div class="tab-pane fade" id="turbo" role="tabpanel">
        <div class="product_carousel product_column5 owl-carousel">

        </div>
      </div>
    </div>
  </div>
</section>
<!--product area end-->

<!--banner area start-->
<section class="banner_area mb-50">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="banner_container">
          <div class="single_banner">
            <div class="banner_thumb">
              <a href="#"><img src="{{app.IMG_PATH}}bg/banner3.jpg" alt=""></a>
              <div class="banner_text">
                <h3>Car Audio</h3>
                <h2>Super Natural Sound</h2>
                <a href="shop.html">Shop Now</a>
              </div>
            </div>
          </div>
          <div class="single_banner">
            <div class="banner_thumb">
              <a href="#"><img src="{{app.IMG_PATH}}bg/banner4.jpg" alt=""></a>
              <div class="banner_text">
                <h3>All - New</h3>
                <h2>Perfomance Parts</h2>
                <a href="shop.html">Shop Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--banner area end-->

<!--custom product area-->
<section class="custom_product_area">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <!--featured product area-->
        <div class="custom_product">
          <div class="section_title">
            <h2><span>Electrónicos</span></h2>
          </div>
          <div class="small_product_items small_product_active">
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product1.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Variable with soldout product for title</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product2.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">JBL Flip 3 Splashproof Portable Bluetooth</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$170.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product3.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Kodak PIXPRO Astro Zoom AZ421 16 MP</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$160.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product4.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product5.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product6.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product1.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Variable with soldout product for title</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product2.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">JBL Flip 3 Splashproof Portable Bluetooth</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$170.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product3.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Kodak PIXPRO Astro Zoom AZ421 16 MP</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$160.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product4.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product5.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product6.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--featured product end-->
      </div>

      <div class="col-lg-4 col-md-12">
        <!--mostview product area-->
        <div class="custom_product">
          <div class="section_title">
            <h2><span>Electrodomésticos</span></h2>
          </div>
          <div class="small_product_items small_product_active">
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product8.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$170.00</span>
                  <span class="old_price">$430.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product9.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$170.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product4.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Kodak PIXPRO Astro Zoom AZ421 16 MP</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$160.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product10.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$160.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product11.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product12.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product8.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$170.00</span>
                  <span class="old_price">$430.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product9.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$170.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product4.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Kodak PIXPRO Astro Zoom AZ421 16 MP</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$160.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product10.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$160.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product11.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product12.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--mostview product end-->
      </div>

      <div class="col-lg-4 col-md-12">
        <!--bestSeller product area-->
        <div class="custom_product">
          <div class="section_title">
            <h2><span>Desporto</span></h2>
          </div>
          <div class="small_product_items small_product_active">
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product10.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Variable with soldout product for title</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product11.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">JBL Flip 3 Splashproof Portable Bluetooth</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$170.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product12.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Kodak PIXPRO Astro Zoom AZ421 16 MP</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$160.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product13.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product14.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product15.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product10.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Variable with soldout product for title</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product11.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">JBL Flip 3 Splashproof Portable Bluetooth</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$170.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product12.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Kodak PIXPRO Astro Zoom AZ421 16 MP</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="regular_price">$160.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product13.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product14.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">Accusantium dolorem Security Camera</a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
            <div class="single_product_items">
              <div class="product_thumb">
                <a href="product-details.html"><img src="{{app.IMG_PATH}}product/product15.jpg" alt=""></a>
              </div>
              <div class="product_content">
                <div class="product_name">
                  <h3><a href="product-details.html">1. New and sale new badge product </a></h3>
                </div>
                <div class="product_ratings">
                  <ul>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                    <li><a href="#"><i class="ion-star"></i></a></li>
                  </ul>
                </div>
                <div class="price_box">
                  <span class="current_price">$180.00</span>
                  <span class="old_price">$420.00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--bestSeller product end-->
      </div>
    </div>
  </div>
</section>
<!--custom product end-->

<!--call to action start-->
<section class="call_to_action" style="display: none;">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="call_action_inner">
          <div class="call_text">
            <h3>Temos <span>Recommendations</span> for You</h3>
            <p>Take 30% off when you spend $150 or more with code Autima11</p>
          </div>
          <div class="discover_now">
            <a href="#">discover now</a>
          </div>
          <div class="link_follow">
            <ul>
              <li><a href="#"><i class="ion-social-facebook"></i></a></li>
              <li><a href="#"><i class="ion-social-twitter"></i></a></li>
              <li><a href="#"><i class="ion-social-googleplus"></i></a></li>
              <li><a href="#"><i class="ion-social-youtube"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--call to action end-->











<div id="content" style="display: none;">
  <div class="so-page-builder">
    <div class="container page-builder-ltr">
      <div class="row row_k43m row-style ">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_hmsd block block_2">
          <div class="home4-banner-1 row clearfix ">
            <div class="item-1 col-lg-4 col-md-4 col-sm-4 col-xs-6 mrb-30 banners">
              <div>
                <a title="Static Image" href="#">
                  <img src="{{app.IMG_PATH}}catalog/demo/banners/home4/banner4-1.jpg" alt="Static Image">
                </a>
              </div>
            </div>
            <div class="item-2 col-lg-4 col-md-4 col-sm-4 col-xs-6 mrb-30 banners">
              <div>
                <a title="Static Image" href="#">
                  <img src="{{app.IMG_PATH}}catalog/demo/banners/home4/banner4-2.jpg" alt="Static Image">
                </a>
              </div>
            </div>
            <div class="item-2 col-lg-4 col-md-4 col-sm-4 hidden-xs mrb-30 banners">
              <div>
                <a title="Static Image" href="#">
                  <img src="{{app.IMG_PATH}}catalog/demo/banners/home4/banner4-3.jpg" alt="Static Image">
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <section id="box-link1" class="section-style">
      <div class="container page-builder-ltr">
        <div class="row row-style row_a1">
          <div class="col_a1c  block block_3 title_neo1">
            <div class="module module so-listing-tabs-ltr home4-lt-style1 default-nav">
              <div class="modcontent">
                <div id="bestOffersSlider" class="so-listing-tabs first-load module">
                  <div class="ltabs-wrap">
                    <div class="ltabs-tabs-container" data-delay="300" data-duration="600" data-effect="starwars"
                      data-ajaxurl="" data-type_source="0" data-lg="4" data-md="3" data-sm="3" data-xs="2"
                      data-margin="0">
                      <!--Begin Tabs-->
                      <div class="head-title font-ct">
                        <h2 class="modtitle">Recomendados</h2>
                      </div>

                      <div class="ltabs-tabs-wrap" style="display: none;">
                        <span class="ltabs-tab-selected"></span>
                        <span class="ltabs-tab-arrow">▼</span>
                        <div class="item-sub-cat">
                          <ul class="ltabs-tabs cf font-ct list-sub-cat">
                            <li class="ltabs-tab tab-sel" data-category-id="1" data-active-content=".items-category-1">
                              <span class="ltabs-tab-label">Best Seller</span> </li>
                            <li class="ltabs-tab " data-category-id="4" data-active-content=".items-category-4"> <span
                                class="ltabs-tab-label">New Arrivals</span> </li>
                          </ul>
                        </div>
                      </div>
                      <!-- End Tabs-->
                    </div>

                    <div class="wap-listing-tabs  products-list grid">
                      <div class="ltabs-items-container">
                        <skeleton-load class="load" *ngIf="loader" [options]="{type:'produtoList', colums: 3, items:8}">
                        </skeleton-load>

                        <!--Begin Items-->
                        <div class="ltabs-items ltabs-items-selected ltabs-items-loaded items-category-1"
                          data-total="6">
                          <div class="ltabs-items-inner ltabs-slider">
                            <div class="ltabs-item" *ngFor="let produto of bestOffers">
                              <div class="item-inner product-thumb transition product-layout">
                                <produto-item [produto]="produto"></produto-item>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ltabs-items items-category-4 grid" data-total="6">
                          <div class="ltabs-loading"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="box-link2" class="section-style">
      <div class="container page-builder-ltr">
        <div class="row row-style row_a2">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_1bi4  col-style block block_5 title_neo2">
            <div class="module so-listing-tabs-ltr default-nav clearfix img-float label-1 home-lt1">
              <div class="head-title font-ct">
                <h2 class="modtitle">As melhores ofertas do mercado</h2>
              </div>
              <div class="modcontent">
                <div id="tabProdutos1" class="so-listing-tabs first-load">
                  <div class="ltabs-wrap">
                    <div class="ltabs-tabs-container" data-delay="300" data-duration="600" data-effect="starwars"
                      data-ajaxurl="" data-type_source="0" data-lg="4" data-md="3" data-sm="3" data-xs="2"
                      data-margin="0">
                      <!--Begin Tabs-->
                      <div class="ltabs-tabs-wrap" style="display: none;">
                        <span class="ltabs-tab-selected"></span>
                        <span class="ltabs-tab-arrow">▼</span>
                        <div class="item-sub-cat">
                          <ul class="ltabs-tabs cf">
                            <li class="ltabs-tab tab-sel" data-category-id="1" data-active-content=".items-category-1">
                              <span class="ltabs-tab-label">Best Seller</span> </li>
                            <li class="ltabs-tab " data-category-id="2" data-active-content=".items-category-2"> <span
                                class="ltabs-tab-label">New Arrivals</span> </li>
                            <li class="ltabs-tab " data-category-id="3" data-active-content=".items-category-3"> <span
                                class="ltabs-tab-label">Most Rating</span> </li>
                          </ul>
                        </div>
                      </div>
                      <!-- End Tabs-->
                    </div>

                    <skeleton-load class="load" *ngIf="loader" [options]="{type:'produtoList', colums: 3, items:8}">
                    </skeleton-load>

                    <div class="wap-listing-tabs ltabs-items-container products-list grid" *ngIf="!loader">
                      <!--Begin Items-->
                      <div class="ltabs-items ltabs-items-selected items-category-1" data-total="16">
                        <div class="ltabs-items-inner ltabs-slider">
                          <div class="ltabs-item" *ngFor="let items of produtos">
                            <div class="item-inner product-layout transition product-grid"
                              *ngFor="let produto of items">
                              <produto-item [produto]="produto"></produto-item>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="ltabs-items items-category-2 grid" data-total="16">
                        <div class="ltabs-loading"></div>
                      </div>

                      <div class="ltabs-items  items-category-3 grid" data-total="16">
                        <div class="ltabs-loading"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="home1-banner-2 clearfix">
              <div class="item-1 col-lg-6 col-md-6 col-sm-6 banners">
                <div>
                  <a title="Static Image" href="#">
                    <img src="{{app.IMG_PATH}}catalog/demo/banners/home1/bn-3.jpg" alt="Static Image">
                  </a>
                </div>
              </div>
              <div class="item-2 col-lg-6 col-md-6 col-sm-6 banners">
                <div>
                  <a title="Static Image" href="#">
                    <img src="{{app.IMG_PATH}}catalog/demo/banners/home1/bn-4.jpg" alt="Static Image">
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section id="box-link" class="section-style">
      <div class="container page-builder-ltr">
        <div class="row row-style row_a3">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_1bi4  col-style block block_5 title_neo3">
            <div class="module so-listing-tabs-ltr default-nav clearfix img-float label-1 home-lt1">
              <div class="head-title font-ct">
                <h2 class="modtitle">Novas Tendências</h2>
              </div>
              <div class="modcontent">
                <div id="tabProdutos2" class="so-listing-tabs first-load">
                  <div class="ltabs-wrap">
                    <div class="ltabs-tabs-container" data-delay="300" data-duration="600" data-effect="starwars"
                      data-ajaxurl="" data-type_source="0" data-lg="4" data-md="3" data-sm="3" data-xs="2"
                      data-margin="0">
                      <!--Begin Tabs-->
                      <div class="ltabs-tabs-wrap" style="display: none;">
                        <span class="ltabs-tab-selected"></span>
                        <span class="ltabs-tab-arrow">▼</span>
                        <div class="item-sub-cat">
                          <ul class="ltabs-tabs cf">
                            <li class="ltabs-tab tab-sel" data-category-id="1" data-active-content=".items-category-1">
                              <span class="ltabs-tab-label">Best Seller</span> </li>
                            <li class="ltabs-tab " data-category-id="2" data-active-content=".items-category-2"> <span
                                class="ltabs-tab-label">New Arrivals</span> </li>
                            <li class="ltabs-tab " data-category-id="3" data-active-content=".items-category-3"> <span
                                class="ltabs-tab-label">Most Rating</span> </li>
                          </ul>
                        </div>
                      </div>
                      <!-- End Tabs-->
                    </div>

                    <skeleton-load class="load" *ngIf="loader" [options]="{type:'produtoList', colums: 3, items:8}">
                    </skeleton-load>

                    <div class="wap-listing-tabs ltabs-items-container products-list grid" *ngIf="!loader">
                      <!--Begin Items-->
                      <div class="ltabs-items ltabs-items-selected items-category-1" data-total="16">
                        <div class="ltabs-items-inner ltabs-slider">
                          <div class="ltabs-item" *ngFor="let items of produtos">
                            <div class="item-inner product-layout transition product-grid"
                              *ngFor="let produto of items">
                              <produto-item [produto]="produto"></produto-item>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="ltabs-items items-category-2 grid" data-total="16">
                        <div class="ltabs-loading"></div>
                      </div>

                      <div class="ltabs-items  items-category-3 grid" data-total="16">
                        <div class="ltabs-loading"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
