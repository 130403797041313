import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from './../../../environments/environment';

@Injectable({
   providedIn: "root"
})
export class ContaService {
   private readonly API = `${environment.api}/contas`

   constructor(
      private http: HttpClient
   ) { }

   async create(values: any): Promise<any> {
      return await this.http.post<any>(this.API+"/create", values).toPromise()
   }

   async getContaInfo() {
      return await this.http.get<object>(this.API)
   }

   async updateContaInfo(contaData: object) {
      return await this.http.post<object>("/update", contaData).toPromise()
   }
}
