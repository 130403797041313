import { AppService } from './../../app.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ProdutoService } from './../produtos/produtos.service';

declare var $: any;
declare var sliderCarroucel: any
declare var produtoColumm5: any
declare var produtoColumm2: any
declare var produtoColumm1:any

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.css'],
})
export class HomeComponent implements OnInit {
  produtos: any[];
  bestOffers: any[] = [];
  loader: boolean = true;

  constructor(
    public app: AppService,
    private route: Router,
    private produtoService: ProdutoService
  ) {}

  setCategoria(categoriaUrl: string, id: string) {
    localStorage.setItem('catUrl', categoriaUrl);
    localStorage.setItem('catId', id);

    this.route.navigate(['lojas']);
  }

  getBestOffersProdutos(){
    this.produtoService.select({}).subscribe((res) => {
      this.bestOffers = res;

      if(this.bestOffers.length > 0){
        setTimeout(() => {
          //bestOfferSlider('#bestOffersSlider');
        }, 100);
      }
    });
  }

  ngOnInit() {
    sliderCarroucel();
    produtoColumm2();
    produtoColumm1()

    this.getBestOffersProdutos()

    this.produtoService.select({ chunck: 2 }).subscribe((res) => {
      this.produtos = res;
      this.loader = false;
      if(this.produtos.length > 0){
        setTimeout(() => {
          //bestOfferSlider('#tabProdutos1');
          //bestOfferSlider('#tabProdutos2');
          produtoColumm5();
        }, 100);
      }

      setTimeout(() => {
        //handleSlick();
      }, 100);
    });
  }
}
