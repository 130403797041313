import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {HttpInterceptor, HttpHandler, HttpHeaders,
HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'

import { CartService } from './../components/store/cart/cart.service';

@Injectable({
   providedIn: "root"
})
export class CartInterceptor implements HttpInterceptor {
   constructor(private CartService: CartService) {}

   intercept(req: HttpRequest<any>, next: HttpHandler){
      const cartToken = this.CartService.getCartToken()
      let request: HttpRequest<any> = req

      if(cartToken){
         request = req.clone({
            headers: req.headers.append("Authorization", `Cart ${cartToken}`),
            //withCredentials: true
         })
      }

      return next.handle(request).pipe(
         catchError(this.handleError)
      )
   }

   handleError(error: HttpErrorResponse){

      /*if(error.error instanceof ErrorEvent){
         console.error("Ocorreu um erro", error.error.message)
      }else{
         console.error(
            `Codigo de Erro: ${error.status}`+
            `Erro: ${JSON.stringify(error.error)}`
         )
      }*/

      return throwError("Ocorreu um erro tente novamente")
   }
}


export const HttpCartInterceptor = [
   //{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
   { provide: HTTP_INTERCEPTORS, useClass: CartInterceptor, multi: true }
]
