<section class="slider_section slider_two mb-50">
  <div class="slider_area owl-carousel">
    <div class="single_slider d-flex align-items-center"
      style="background-image: url({{app.IMG_PATH}}slider/slider4.jpg);">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="slider_content">
              <h2>HP Racer Skutex</h2>
              <h1>Feel The Greatest Oil Power With Best One Oil</h1>
              <a class="button" href="shop.html">shopping now</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="single_slider d-flex align-items-center"
      style="background-image: url({{app.IMG_PATH}}slider/slider4.jpg);">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="slider_content">
              <h2>Special Offer</h2>
              <h1>Get &250 In Total Discount On A New Set Of Tries</h1>
              <a class="button" href="shop.html">shopping now</a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="single_slider d-flex align-items-center"
      style="background-image: url({{app.IMG_PATH}}slider/slider4.jpg);">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="slider_content">
              <h2>Over 15,000</h2>
              <h1>Remanufactured Low Milage Used Engines For Sale</h1>
              <a class="button" href="shop.html">shopping now</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
