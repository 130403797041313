<footer class="footer_widgets" style="background-color: #f2f2f2;">
  <div class="container">
    <div class="footer_top">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="widgets_container contact_us">
            <div class="footer_logo">
              <a routerLink="">
                <img src="{{app.IMG_PATH}}logo/logo.png" alt="">
              </a>
            </div>

            <div class="footer_contact">
              <p>We are a team of designers and developers that
                create high quality Magento, Prestashop, Opencart...</p>
              <p><span>Endereço</span> Centro comercial King Monami</p>
              <p><span>Contactos</span>
                Movicel: <a href="">900 000 000</a>
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="widgets_container widget_menu">
            <h3>Informações</h3>
            <div class="footer_menu">
              <ul>
                <li><a routerLink="">Sobre Nós</a></li>
                <li><a routerLink="">Informações de entrega</a></li>
                <li><a routerLink="">Políticas & Privacidade</a></li>
                <li><a routerLink="">Termos & Condições</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="widgets_container widget_menu">
            <h3>Extras</h3>
            <div class="footer_menu">
              <ul>
                <li><a routerLink="">Minha Conta</a></li>
                <li><a routerLink="">Histórico de compras</a></li>
                <li><a routerLink="">Wishlist</a></li>
                <li><a routerLink="">Checkout</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="widgets_container">
            <h3>Subscreva a nossa Newsletter</h3>
            <p>Seja o primeiro a saber das nossas novidades.</p>
            <div class="subscribe_form">
              <form id="mc-form" class="mc-form footer-newsletter">
                <input id="mc-email" type="email" autocomplete="off" style="background: #fff;"
                placeholder="Digite o seu email aqui..." />
                <button id="mc-submit">Assinar</button>
              </form>

              <!-- mailchimp-alerts Start -->
              <div class="mailchimp-alerts text-centre">
                <div class="mailchimp-submitting"></div><!-- mailchimp-submitting end -->
                <div class="mailchimp-success"></div><!-- mailchimp-success end -->
                <div class="mailchimp-error"></div><!-- mailchimp-error end -->
              </div><!-- mailchimp-alerts end -->

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="copyright_area">
            <p>Copyright &copy; {{copyright}} Inoarena. Todos os direitos reservados.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="footer_payment text-right">
            <a >
              <img src="{{app.IMG_PATH}}icon/payment.png" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>












<footer class="footer-container typefooter-2" style="display: none;">
  <div class="footer-has-toggle collapse" id="collapse-footer">
    <div class="so-page-builder bg-dark">
      <section class="section_1  section-color bg-white">
        <div class="container page-builder-ltr">
          <div class="row row_m1ch  row-style  row-color ">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_itqc  col-style">
              <div class="clearfix bonus-menus">
                <ul class="row">
                  <li class="item free col-md-3">
                    <div class="icon">
                      <img src="{{app.IMG_PATH}}catalog/demo/banners/home2/icon-1.png" alt="Static Image">
                    </div>
                    <div class="text">
                      <h5><a href="#">Entregas Gratis</a></h5>
                      <p class="no-transforme">Apartir de 2000kz em compra.</p>
                    </div>
                  </li>
                  <li class="item secure col-md-3">
                    <div class="icon">
                      <img src="{{app.IMG_PATH}}catalog/demo/banners/home2/icon-2.png" alt="Static Image">
                    </div>
                    <div class="text">
                      <h5><a href="#">Pagamento seguro</a></h5>
                      <p class="no-transforme">Nos preocupamos com o seu dinheiro</p>
                    </div>
                  </li>
                  <li class="item support col-md-3">
                    <div class="icon">
                      <img src="{{app.IMG_PATH}}catalog/demo/banners/home2/icon-3.png" alt="Static Image">
                    </div>
                    <div class="text">
                      <h5><a href="#">Suporte online</a></h5>
                      <p class="no-transforme">Temos um suporte online 24/7</p>
                    </div>
                  </li>
                  <li class="item payment col-md-3">
                    <div class="icon">
                      <img src="{{app.IMG_PATH}}catalog/demo/banners/home2/icon-4.png" alt="Static Image">
                    </div>
                    <div class="text">
                      <h5><a href="#">Pagamento na entrega</a></h5>
                      <p class="no-transforme">Pagque o seu produto quando receber</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_2  section-color ">
        <div class="container page-builder-ltr">
          <div class="row row_34bo  row-style ">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_9jik  col-style">
              <div class="module news-letter">
                <div class="so-custom newsletter" style="width:100%     ; background-color: #f0f0f0 ; ">
                  <div class="btn-group title-block">
                    <div class="popup-title page-heading">
                      <i class="fa fa-paper-plane-o"></i> Assine a nossa newsletters
                    </div>
                    <div class="newsletter_promo">E seja o primeiro a saber das nossas novidades</div>
                  </div>
                  <div class="modcontent block_content">
                    <form method="post" id="signup" name="signup" class="form-group form-inline signup send-mail">
                      <div class="input-group form-group required">
                        <div class="input-box">
                          <input type="email" placeholder="Your email address..." value="" class="form-control"
                            id="txtemail" name="txtemail" size="55">
                        </div>
                        <div class="input-group-btn subcribe">
                          <button class="btn btn-primary" type="submit" onclick="return subscribe_newsletter();"
                            name="submit">
                            <i class="fa fa-envelope hidden"></i>
                            <span>Inscrever-se</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!--/.modcontent-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_3 bg-dark">
        <div class="container page-builder-ltr">
          <div class="row row_bh6y  row-style ">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_mehx  col-style">
              <div class="row row_q34c  border ">
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col_5j8y col-style">
                  <div class="contactinfo">
                    <img src="{{app.IMG_PATH}}logo/logo.png" style="width: 65%;" title="" alt="" />
                    <p></p>
                    <div class="content-footer">
                      <div class="address">
                        <label><i class="fa fa-map-marker" aria-hidden="true"></i></label>
                        <span>Luanda -Angola</span>
                      </div>
                      <div class="phone">
                        <label><i class="fa fa-phone" aria-hidden="true"></i></label>
                        <span>(+244) 900 000 000</span>
                      </div>
                      <div class="email">
                        <label><i class="fa fa-envelope"></i></label>
                        <a href="#">geral@xawandi.com</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col_oz7e col-style">
                  <div class="footer-links">
                    <h4 class="title-footer">Informações</h4>
                    <ul class="links">
                      <li><a routerLink="sobre">Sobre Nós</a></li>
                      <li><a routerLink="contactos">Contactos</a></li>
                      <li><a>Suporte</a></li>
                      <li><a>Termos e condições</a></li>
                      <li><a> Políticas de privacidade</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col_l99d col-style">
                  <div class="footer-links">
                    <h4 class="title-footer">Minha conta</h4>
                    <ul class="links">
                      <li><a>Minha Conta</a></li>
                      <li><a>Checkout</a></li>
                      <li><a>Favoritos</a></li>
                      <li><a>Histório de compras</a></li>
                      <li><a>Transações</a></li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12 col_lv09 col-style">
                  <div
                    class="module so-latest-blog footer_lastestblog footer-links  preset01-4 preset02-4 preset03-3 preset04-2 preset05-1">
                    <h4 class="title-footer"><span>Lastest From Blog</span></h4>
                    <div class="modcontent">
                      <div id="so_latest_blog_736" class="so-blog-external button-type2 button-type2">
                        <div class="blog-external">
                          <div class="blog-external-simple">
                            <div class="cat-wrap">
                              <div class="media">
                                <div class="item">
                                  <div class="media-left">
                                    <a href="#" target="_self">
                                      <img src="{{app.IMG_PATH}}catalog/demo/blog/8-65x50.jpg"
                                        alt="Aestibulum ipsum a ornare car" class="media-object" />
                                    </a>
                                  </div>
                                  <div class="media-body">
                                    <h4 class="media-heading">
                                      <a href="#" title="Aestibulum ipsum a ornare car" target="_self">Aestibulum ipsum
                                        a ornare car</a>
                                    </h4>
                                    <div class="media-content">
                                      <div class="media-subcontent">
                                        <span class="media-author"><span class="txt">By:
                                          </span><span class="author">ytcdemo</span></span>
                                        <div class="media-date-added"><i class="fa fa-calendar"></i>
                                          October 17th, 2017</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section_4 bg-dark" style="background-color: #222 !important;">
        <div class="container page-builder-ltr">
          <div class="row row_njct  row-style ">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col_7f0l  col-style">
              <div class="border">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 item-1">
                    <div class="app-store spcustom_html">
                      <div>
                        <a class="app-1" href="#">google store</a>
                        <a class="app-2" href="#">apple store</a>
                        <a class="app-3" href="#">window store</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 item-2">
                    <div class="footer-social">
                      <h3 class="block-title hidden">Follow us</h3>
                      <div class="socials">
                        <a href="https://www.facebook.com/SmartAddons.page" class="facebook" target="_blank">
                          <i class="fa fa-facebook"></i>
                          <p>on</p>
                          <span class="name-social">Facebook</span>
                        </a>
                        <a href="https://twitter.com/smartaddons" class="twitter" target="_blank">
                          <i class="fa fa-twitter"></i>
                          <p>on</p>
                          <span class="name-social">Twitter</span>
                        </a>
                        <a href="https://plus.google.com/u/0/+SmartAddons-Joomla-Magento-WordPress/posts" class="google"
                          target="_blank">
                          <i class="fa fa-google-plus"></i>
                          <p>on</p>
                          <span class="name-social">Google +</span>
                        </a>
                        <a href="#" class="dribbble" target="_blank"><i class="fa fa-dribbble"
                            aria-hidden="true"></i></a>
                        <a href="#" class="instagram" target="_blank">
                          <i class="fa fa-instagram" aria-hidden="true"></i>
                          <p>on</p>
                          <span class="name-social">Instagram</span>
                        </a>
                        <a href="#" class="pinterest" target="_blank"><i class="fa fa-pinterest"></i></a> <a href="#"
                          class="linkedIn" target="_blank"><i class="fa fa-linkedin"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>

  <div class="footer-toggle hidden-lg hidden-md bg-gray">
    <a class="showmore collapsed" data-toggle="collapse" href="#collapse-footer" aria-expanded="false"
      aria-controls="collapse-footer">
      <span class="toggle-more"><i class="fa fa-angle-double-down"></i>Show More</span>
      <span class="toggle-less"><i class="fa fa-angle-double-up"></i>Show less</span>
    </a>
  </div>

  <div class="footer-bottom bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-md-7  col-sm-7 copyright">
          © 2020. Todos os direitos reservados Xawandi Store
        </div>
        <div class="col-md-5 col-sm-5 paymen">
          <img src="{{app.IMG_PATH}}catalog/demo/payment/payments-1.png" alt="imgpayment">
        </div>
      </div>
    </div>
  </div>
</footer>




<div class="modal " id="bsModalCart" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">
          <i class="fa fa-check-circle" style="color: #8ac711;"></i>
          Item adicionado ao carrinho com sucesso.
        </h5>
        <button class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <a routerLink="/cart" type="button" class="btn btn-primary" (click)="shared.bsModalClose('#bsModalCart')"
          style="margin-right: 10px;"> Ver carrinho</a>

        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" style="text-transform: none;">
          Continuar a comprar</button>
      </div>
    </div>
  </div>
</div>
