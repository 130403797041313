import { AppService } from './../app.service';
import { Injectable } from "@angular/core";

import { LoadingBarService } from '@ngx-loading-bar/core';
//import { NgxSpinnerService } from 'ngx-spinner';

//import { InputCheckService } from './plugins/input-check/input-check.service';

declare var alertify: any
declare var $: any

@Injectable({
   providedIn: "root"
})
export class SharedService{

   constructor(
      private app: AppService,
      private loadingBar: LoadingBarService
   ){}

   inputCheck(option: any ={}){
      //this.inputCheckService.inputCheckService(option)
   }

   alertPush(option: any = {}){
      alertify.set('notifier','position', option.position);
      alertify.notify(option.msg, option.type, option.delay);
   }

   alertMsg(option: any = {}) {
      var div = document.querySelector(option.div);
      div.innerHTML =
         `
      <div class="alert alert-` +
         option.type +
         `" role="alert" style="display: none">
         ` +
         option.msg +
         `
      </div>
      `;
      $('.alert').fadeIn('slow', function () {
         $(this).delay(option.delay).fadeOut('slow');
      });
   }

   quantity(type: string, control: any){
      if(type == "inc"){
         let inc = control.value; inc++;
         control.setValue(inc);
      }

      if(type == "dec"){
         let dec = control.value; dec--;
         control.setValue(control.value <= 1 ? 1 : dec);
      }
   }

   quantityInput(type: string, inputElement: any){
      const input = document.querySelector(inputElement)

      if(type == "inc"){
         let inc = input.value; inc++;
         input.value = inc;
         input.setAttribute("data-value", inc)
      }

      if(type == "dec"){
         let dec = input.value; dec--;
         input.value = input.value <= 1 ? 1 : dec;
         input.setAttribute("data-value", input.value)
      }
   }

   spinner(visible: boolean, options: any) {
      let spinner = <HTMLElement> document.querySelector('.app-spinner');
      spinner.innerHTML = `
      <div class="d-flex justify-content-center">
         <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
         </div>
      </div>
      `
      spinner.style.display = visible ? 'block' : 'none'
   }

   btnSpinner(status, options: any) {
      var btn = <any>document.querySelector(options.btn);

      if (status) {
         this.loadingBar.start()
         btn.setAttribute('disabled', true);
         const img =
         options.color && options.color == 'dark'? 'load-black.svg' : 'load-white.svg';
         let noSpinner = '<img class="btnSpinner" style="width: 20px" src="'+this.app.IMG_PATH +img +'">'

         btn.insertAdjacentHTML('afterbegin',
            !options.hasOwnProperty('noSpinner') ? noSpinner : ''
         );
      }

      if (!status) {
         this.loadingBar.complete()
         btn.removeAttribute('disabled');
         if(!options.hasOwnProperty('noSpinner')){
            let spinner = <any>document.querySelector('.btnSpinner');
            spinner.remove();
         }
      }
   }

   startBtnLoad(options: any = {}){
      var btn = document.querySelector(options.class);
      if(btn != null){
         const img = options.type == "white" ? "load-white.svg" : "load-black.svg"
         const label = options.label ? options.label : 'Aguarde'

         btn.innerHTML =
         '<img style="width: '+options.width+'px" src="'+this.app.IMG_PATH+img+'"> '+label+'';
         btn.setAttribute("disabled", true);
      }
   }

   stopBtnLoad(options: any = {}){
      var btn = document.querySelector(options.class);
      if(btn != null){
         btn.innerHTML = options.label;
         btn.removeAttribute("disabled");
      }
   }

   bsModalClose(bsModal){
      $(bsModal).modal("hide")
   }

   bsModal(options: any){
      options.show ? $(options.id).modal("show") : $(options.id).modal("hide")
   }
}
