import { AppService } from './../../app.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SharedService } from './../../shared/shared.service';
import { FormValidationService } from './../../shared/forms/form.validation';

import { AuthService } from './auth.service';

@Component({
   selector: 'app-auth',
   templateUrl: './auth.html'
})
export class AuthComponent implements OnInit {
   authFormGroup: FormGroup
   submitForm: boolean

   constructor(
      public app: AppService,
      public shared: SharedService,
      public form: FormValidationService,
      private authService: AuthService,
      private formBuilder: FormBuilder,
      private router: Router
   ) {}

   async signIn(){
      this.submitForm = true
      if(this.authFormGroup.valid){
         this.shared.btnSpinner(true, {btn:".btnAuthSpinner"})

         await this.authService.authenticate(this.authFormGroup.value).then(res =>{
            this.shared.btnSpinner(false, {btn:".btnAuthSpinner"})
            this.shared.alertMsg({
               show: true,
               delay:500,
               div:'.alertAuth',
               type: res.token ? 'success' : 'danger',
               msg: res.token ? "Autenticação efectuada com sucesso": res.msg
            })

            if(res.token){
               setTimeout(()=>{
                  this.shared.bsModal({show:false, id:'#bsModalCreateConta'})
                  this.authService.signed.emit(true)
                  this.router.navigate(['/painel'])
               },1000)
            }
         })
      }
   }

   ngOnInit(){
      this.authFormGroup = this.formBuilder.group({
         login: ['', Validators.required],
         password: ['', Validators.required],
         origin:['site']
      })
   }

   get authForm(){ return this.authFormGroup.controls }

}
