<form [formGroup]="authFormGroup" (ngSubmit)="signIn()">
   <div class="row">
      <div class="col-md-12 alertAuth"></div>
      <div class="col-md-12 form-group">
         <input type="email" name="login" formControlName="login" class="form-control" placeholder="Email"
         [ngClass]="form.classError(submitForm, authForm.login)">
         <span class="danger-text">
            {{form.validate('required', submitForm, authForm.login, null)}}
         </span>
      </div>

      <div class="col-md-12 form-group">
         <input type="password" name="login" formControlName="password"
         class="form-control" placeholder="Senha">
         <span class="danger-text">
            {{form.validate('required', submitForm, authForm.password, null)}}
         </span>
      </div>

      <div class="col-md-12">
         <button type="submit" class="btn btn-primary btnAuthSpinner btn-100">Entrar</button>
      </div>
   </div>
</form>
