import { AppService } from './../../../app.service';

import { Component, OnInit } from '@angular/core';
import { ConfigService } from './../../../services/config.service';

declare var $: any
declare var handleSlideHome: any

@Component({
  selector: 'app-destaques',
  templateUrl: './destaques.html',
  styleUrls: ['./destaques.css']
})
export class DestaquesComponent implements OnInit {

  constructor(public app: AppService) { }

  ngOnInit(): void {
    //handleSlideHome()
  }
}
