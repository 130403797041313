import { Injectable } from '@angular/core';


@Injectable({ providedIn: "root" })
export class ConfigService {

   IMG_PATH: string = "../../../assets/uploads/"

   constructor(){}
}
