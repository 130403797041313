import { SharedService } from './../../../shared/shared.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CategoriasService } from 'src/app/components/categorias/categorias.service';
import { AppService } from './../../../app.service';
import { AuthService } from '../../auth/auth.service';

declare var toogleCategorias: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.html',
  styleUrls: ['./nav.css'],
})
export class NavComponent implements OnInit {
  categorias: any[];
  loaderCart: boolean = true;

  navMenu: object = [
    { title: 'Início', url: '' },
    { title: 'Lojas', url: 'sobre' },
  ];

  constructor(
    public app: AppService,
    public shared: SharedService,
    private categoriasService: CategoriasService
  ) {}

  signOut() {}

  ngOnInit(): void {
    toogleCategorias();

    this.categoriasService.select().subscribe((res) => {
      this.categorias = res;
      this.loaderCart = false;
    });
  }
}
